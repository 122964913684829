import React, {FC} from 'react';

import Loader from '@components/loader';

import AxiosClient from './AxiosClient';
import useConfig from './providers/configProvider/useConfig';
import AppRoutingModule from './pages/AppRoutingModule';

const AuthenticationChecker:FC = () => {
  const {
    config,
    activeTheme,
  } = useConfig();

  return !config || !activeTheme
    ? (
      <Loader
        isAbsolute={true}
        isHeightFull={true}
        isWidthFull={true}
      />
    )
    : (
      <AxiosClient>
        <AppRoutingModule />
      </AxiosClient>
    );
};

export default AuthenticationChecker;
