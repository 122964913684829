import React, {FC, lazy, Suspense} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Loader from '@components/loader';

import {UserProvider} from '@providers/userProvider/UserProvider';
import {DeviceMatchProvider} from '@providers/deviceMatchProvider/useDeviceMatchProvider';

const WelcomePage = lazy(() => import('./Welcome'));
const KioskScan = lazy(() => import('./KioskScan'));
const Register = lazy(() => import('./Register'));
const Photos = lazy(() => import('./Photos'));
const DirectView = lazy(() => import('./DirectView'));

const AppRoutingModule: FC = () => (
  <Suspense
    fallback={
      <Loader
        isAbsolute={true}
        isHeightFull={true}
        isWidthFull={true}
      />
    }
  >
    <BrowserRouter>
      <UserProvider>
        <DeviceMatchProvider>
          <Routes>
            <Route
              path="/scan"
              element={
                <KioskScan />
              }
            />

            <Route
              path="/login"
              element={
                <Register />
              }
            />

            <Route
              path="/photos"
              element={
                <Photos />
              }
            />

            <Route
              path="/direct-view"
              element={
                <DirectView />
              }
            />

            <Route
              path="/"
              element={
                <WelcomePage />
              }
            />
          </Routes>
        </DeviceMatchProvider>
      </UserProvider>
    </BrowserRouter>
  </Suspense>
);

export default AppRoutingModule;
