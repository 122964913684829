import React, {FC} from 'react';
import axios from 'axios';
import {configure} from 'axios-hooks';

import {ConfigClientEnum} from '@interfaces/enums/apiData.enum';

import useConfig from './providers/configProvider/useConfig';

const AxiosClient: FC = ({children}) => {
  const {config} = useConfig();

  const instance = axios.create({
    baseURL: config?.API_URI,
  });

  if (config?.client === ConfigClientEnum.cgg) {
    instance.interceptors.request.use((axiosConfig) => {
      axiosConfig.params = {
        ...axiosConfig.params,
        accessToken: 'eV2njHP7yffnM30hB630nqwo3M5rllxUPoQ70zCdvjg==',
      };
      return axiosConfig;
    }, (err: any) => {
      console.log('axios err: ', err);
    });
  }

  configure({axios: instance, cache: false});

  return (
    <>
      {children}
    </>
  );
};

export default AxiosClient;
