import React, {FC, createContext} from 'react';

import useConfigState from './useConfigState';

const ConfigContext = createContext<any | undefined>(undefined);

const ConfigProvider: FC = ({children}) => {
  const value = useConfigState();

  return (
    <ConfigContext.Provider value={value}>
      {children}
    </ConfigContext.Provider>
  );
};

export {ConfigProvider, ConfigContext};
