import {useEffect, useState} from 'react';

import {IDeviceMatchState} from './deviceMatch.types';

const useIsDesktop = (): IDeviceMatchState => {
  const [deviceMatch, setDevicematch] = useState<IDeviceMatchState>({
    isMobile: window.matchMedia('(max-width: 600px)').matches,
  });

  useEffect(() => {
    window
    .matchMedia('(max-width: 600px)')
    .addEventListener('change', e => setDevicematch({
      isMobile: e.matches,
    }));
  }, []);

  return {...deviceMatch};
};

export default useIsDesktop;
