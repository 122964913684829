export default {
  config: '/config.json',
  activeTheme: (params: string): string => `/expressions/gallery/themes/active?${params}`,
  guestInfo: (barcode: string): string => `/expressions/gallery/guest?ticketBarcode=${barcode}`,
  saveGuest: '/expressions/gallery/guest',
  guestPhotos: (barcode: string): string => `/expressions/gallery/themes/active/media?ticketBarcode=${barcode}`,
  mediaShare: (id: string): string => `/expressions/media/${id}/share`,
  mediaLayer: (mediaId: string): string => `/expressions/media/${mediaId}/media-layers`,
  postSurveyAnswer: '/expressions/gallery/postSurveyAnswers',
  getMedia: (mediaId: string): string => `/expressions/media/${mediaId}`,
};
