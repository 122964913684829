import {useEffect, useState, useCallback} from 'react';
import {AxiosResponse} from 'axios';
import useAxios from 'axios-hooks';

import api from '@api/index';
import {IResponseDto} from '@interfaces/rest/response.dto';
import {IActiveThemeDto, IApiDataDto, IThemeStyle, IThemeStyleInstance} from '@interfaces/rest/apiData.dto';
import {IApiDataConfig, IApiDataState, IStyleConfig} from '@interfaces/types/apiData.type';
import {
  ConfigClientEnum,
  ClientCompanyNameEnum,
  VenueNameEnum,
} from '@interfaces/enums/apiData.enum';
import snStyles from '@theme/sn.json';
import cggStyles from '@theme/cgg.json';

const useConfigState = (): IApiDataState => {
  const [config, setConfig] = useState<IApiDataConfig | null>(null);
  const [activeTheme, setActiveTheme] = useState<IActiveThemeDto | null>(null);

  const [{}, getConfig] = useAxios<IApiDataDto>({
    url: api.config,
    method: 'get',
  }, {manual: true});

  const [{}, getActiveTheme] = useAxios<IResponseDto<IActiveThemeDto>>({
    method: 'get',
  }, {manual: true});

  const getCurrentActiveTheme = useCallback((
    url: string,
    client: ConfigClientEnum,
  ) => {
    getActiveTheme({
      url: url + api.activeTheme(
        client === ConfigClientEnum.sn
          ? ''
          : 'accessToken=eV2njHP7yffnM30hB630nqwo3M5rllxUPoQ70zCdvjg=='
      ),
    })
    .then((response: AxiosResponse) => {
      setActiveTheme(response.data.response);
      document.styleSheets[0].insertRule(`:root{--background:url(${response.data.response.backgroundImage})}`);

      const iconLinkDomObj =
        document.querySelector('link[rel="icon"][type="image/x-icon"]') as HTMLLinkElement;
      if (iconLinkDomObj) {
        iconLinkDomObj.href = response.data.response.faviconImageLow;
      }
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    getConfig()
      .then((response: AxiosResponse) => {
        const clientDomain = window.location.hostname.split('-')[0] as ConfigClientEnum;
        const beforeUrl: ConfigClientEnum = Object.keys(ConfigClientEnum).includes(clientDomain)
          ? clientDomain
          : ConfigClientEnum.sn;

        const client: ConfigClientEnum = window.location.hostname === 'localhost'
          ? ConfigClientEnum.sn
          : beforeUrl;

        setConfig({
          API_URI: response.data.API_URI,
          client,
          companyName: ClientCompanyNameEnum[client],
          venueName: VenueNameEnum[client],
          isKiosk: params.get('mode') === 'kiosk' || params.get('mode') === 'testkiosk',
        });

        getCurrentActiveTheme(response.data.API_URI, client);
      });
  }, []);

  useEffect(() => {
    if (config) {
      const themes = {
        sn: snStyles,
        cgg: cggStyles,
      };
      const currentThemeConfig: IStyleConfig = themes[config.client];
      type StyleConfigPropsArray = Array<keyof IStyleConfig>;
      const propsArray: StyleConfigPropsArray = Object.keys(currentThemeConfig) as StyleConfigPropsArray;

      propsArray.forEach((keyName: keyof IStyleConfig) => {
        document.styleSheets[0].insertRule(`:root{${keyName}:${currentThemeConfig[keyName]}}`);
      });
    }
  }, [config?.client]);

  const transformStyleColorsToVars = useCallback((styles: IThemeStyle) => {
    type StyleKeysArray = Array<keyof IThemeStyle>;
    const propsArray: StyleKeysArray = Object.keys(styles) as StyleKeysArray;
    propsArray.forEach((keyName: keyof IThemeStyle) => {
      type StyleInstanceKeyArray = Array<keyof IThemeStyleInstance>;
      const stylnstanceKeyArray: StyleInstanceKeyArray = Object.keys(styles[keyName]) as StyleInstanceKeyArray;
      stylnstanceKeyArray.forEach((instanceKey: keyof IThemeStyleInstance) => {
        document.styleSheets[0].insertRule(`:root{--${keyName}-${instanceKey}:${styles[keyName][instanceKey]}}`);
      });
    });

  }, []);

  useEffect(() => {
    if (activeTheme) {
      transformStyleColorsToVars(activeTheme.style);
    }
  }, [activeTheme]);

  return {
    config,
    activeTheme,
  };
};

export default useConfigState;
