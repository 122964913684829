import {useContext} from 'react';

import {IApiDataType} from '@interfaces/types/apiData.type';
import {ConfigContext} from '@providers/configProvider/ConfigProvider';

const useConfig = (): IApiDataType => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfig must be used within a provider');
  }

  return context;
};

export default useConfig;
